<template>
    <div class="relative flex justify-center items-center">
      <!-- Product Image -->
      <img :src="imageUrl || 'https://via.placeholder.com/150/0000FF/808080?text=No+Image'"
           alt="Product Image"
           class="w-50 h-50 object-cover rounded-md shadow-sm hover:scale-105 transition-transform duration-300 ease-in-out" />
  
      <!-- Hover Menu (Shop and Upvote) -->
      <div class="absolute inset-0 flex justify-center items-center space-x-4 opacity-0 hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50 rounded-md">
        <!-- Shop Button -->
        <a :href="productUrl" target="_blank" class="text-white text-4xl focus:outline-none">
          <button class="text-white text-4xl focus:outline-none">
            🛒
          </button>
        </a>
  
        <!-- Upvote Button -->
        <button @click="upvoteProduct" class="text-white text-4xl focus:outline-none">
          👍
        </button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, onMounted, toRefs } from 'vue';
  
  // Props passed to the component
  const props = defineProps({
    imageUrl: {
      type: String,
      required: true,
    },
    productUrl: {
      type: String,
      required: false,
    },
    productId: {
      type: [String, Number],
      required: true,
    },
    packId: {
      type: [String, Number],
      required: false,
    },
  });
  
  // Destructure props into reactive references
  const { packId, productId } = toRefs(props);
  
  // Function to handle upvote
  const upvoteProduct = () => {
    const upvoteUrl = `https://api.starter-pack.online/starterpacks/${packId.value}/products/${productId.value}/upvote`;
  
    fetch(upvoteUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
          console.log('Upvoted successfully!');
          // Optionally: You can also update the UI or emit an event to reflect the upvote count change.
        } else {
          console.error('Failed to upvote');
        }
      })
      .catch(error => {
        console.error('Error upvoting:', error);
      });
  };
  
  onMounted(() => {
    console.log('imageUrl:', props.imageUrl);
    console.log('productUrl:', props.productUrl);
    console.log('productId:', props.productId);
    console.log('packId:', props.packId);
  });
  </script>
  
  
  <style scoped>
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .opacity-0 {
    opacity: 0;
  }
  .hover\:opacity-100:hover {
    opacity: 1;
  }
  .bg-opacity-50 {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .inset-0 {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .space-x-4 > :not([hidden]) ~ :not([hidden]) {
    margin-left: 1rem;
  }

  button:hover {
    transform: scale(1.2);
  }
  </style>
  