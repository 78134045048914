<template>
  <div class="border rounded-lg shadow-md p-4 bg-white hover:shadow-lg transition-shadow duration-300 ease-in-out max-w-sm mx-auto">
    <!-- Title of the Pack with Link to /starterpacks/:id -->
    <router-link :to="`/starterpacks/${pack.id}`" class="no-underline hover:underline">
      <h2 class="text-2xl font-bold text-gray-800 text-center mb-4 cursor-pointer transition-colors duration-300 ease-in-out hover:text-blue-600">
        {{ pack.name }}
      </h2>
    </router-link>

    <!-- Total Upvotes -->
    <div class="flex justify-center items-center space-x-2 text-sm text-gray-600 mb-4">
      <svg class="w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd"
          d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-4a1 1 0 00-1 1v4a1 1 0 001 1 1 1 0 001-1V7a1 1 0 00-1-1zm-4 4a1 1 0 011-1h2a1 1 0 110 2H7a1 1 0 01-1-1z"
          clip-rule="evenodd" />
      </svg>
      <span>Total Upvotes: {{ totalUpvotes }}</span>
    </div>

    <!-- Display the First 6 Product Images (sorted by upvotes) -->
    <div class="grid grid-cols-2 gap-2">
      <ProductImage 
        v-for="(product, index) in topSixProducts" 
        :key="index" 
        :image-url="product.info.image_url" 
        :product-url="product.info.product_url"
        :product-id="product.id"
        :pack-id="pack.id" 
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import ProductImage from './ProductImage.vue'; // Importing the reusable ProductImage component

// Props passed to the component
const props = defineProps({
  pack: {
    type: Object,
    required: true
  }
});

// Computed property to calculate the total upvotes
const totalUpvotes = computed(() => {
  return props.pack.products.reduce((acc, product) => acc + product.upvotes, 0);
});

// Computed property to get the top 6 products sorted by upvotes
const topSixProducts = computed(() => {
  return [...props.pack.products]
    .sort((a, b) => b.upvotes - a.upvotes) // Sort by upvotes (highest first)
    .slice(0, 6); // Take the top 6 products
});
</script>

<style scoped>
/* Add hover effect to the title */
.cursor-pointer {
  transition: color 0.3s ease-in-out;
}

.cursor-pointer:hover {
  color: #1D4ED8; /* Blue hover color */
}

/* Optional: Adjust hover effect for the card */
.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>
