import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/tailwind.css'
import router from './router' // Import the router
import VueGtag from "vue-gtag-next";


const app = createApp(App)


app.use(VueGtag, {
  property: {
    id: 'G-29BP8Z2YY9', // Your Google Analytics measurement ID
  }
}, router); // Make sure to pass the router if you are using one

// Use the router
app.use(router)

app.mount('#app')
