<template>
  <div class="container mx-auto py-10 text-center">
    <h1 class="text-4xl font-bold mb-4">Welcome to Starter Packs</h1>
    <p class="text-lg mb-10">
      Discover and explore a variety of starter packs that make your life easier. 
      Find packs tailored for your needs, or create your own custom pack!
    </p>

    <!-- Packs Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <CreatePackComponent />
      <CompactCardPack v-for="(pack, index) in packs" :key="index" :pack="pack" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import CompactCardPack from '../components/CompactCardPack.vue'
import CreatePackComponent from '../components/CreatePackComponent.vue';

// Ref to store the fetched packs
const packs = ref([]);

// Fetch starter packs from the API on component mount
onMounted(async () => {
  try {
    const response = await fetch('https://api.starter-pack.online/starterpacks/');
    if (!response.ok) throw new Error('Failed to fetch starter packs');
    const data = await response.json();
    packs.value = data;
    // sort pack by suming votes in their products
    packs.value.sort((a, b) => {
      const sumVotesA = a.products.reduce((acc, product) => acc + product.upvotes, 0);
      const sumVotesB = b.products.reduce((acc, product) => acc + product.upvotes, 0);
      return sumVotesB - sumVotesA;
    });
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped>
/* Add any styles specific to this view if needed */
</style>
