<template>
  <div v-if="loading" class="text-center mt-4">
    <span>Loading...</span>
  </div>
  <div v-else-if="error" class="text-center mt-4 text-red-500">
    <span>{{ error }}</span>
  </div>
  <div v-else-if="pack" class="border rounded-lg shadow-md p-4 bg-white">
    <!-- Existing content -->


    <!-- Title of the Pack -->
    <h2
      class="text-2xl font-bold text-gray-800 text-center mb-4 cursor-pointer transition-colors duration-300 ease-in-out hover:text-blue-600">
      {{ pack.name }}
    </h2>

    <!-- Total Upvotes -->
    <div class="flex justify-center items-center space-x-2 text-sm text-gray-600 mb-4">
      <svg class="w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd"
          d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-4a1 1 0 00-1 1v4a1 1 0 001 1 1 1 0 001-1V7a1 1 0 00-1-1zm-4 4a1 1 0 011-1h2a1 1 0 110 2H7a1 1 0 01-1-1z"
          clip-rule="evenodd" />
      </svg>
      <span>Total Upvotes: {{ totalUpvotes }}</span>
    </div>

    <!-- Display the First 6 Product Images (sorted by upvotes) -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4">
      <div v-for="(product, index) in topSixProducts" :key="index" class="h-full">
        <ProductCard :product="product" class="h-full" :pack-id="packId" />
      </div>
      <AddProductComponent :packId="packId" />
    </div>
  </div>
  <div v-else class="text-center mt-4 text-gray-500">
    <span>No data available</span>
  </div>
</template>




<script setup>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router'; // To access route params
import axios from 'axios';
import ProductCard from '../components/ProductCard.vue'; // Importing the reusable ProductCard component
import AddProductComponent from '../components/AddProductComponent.vue';

// State to store pack data, loading status, and errors
const pack = ref(null);
const loading = ref(true);
const error = ref(null);

// Fetch data function using Axios and environment variable
const fetchPackData = async (id) => {
  try {
    const apiUrl = "https://api.starter-pack.online";
    const response = await axios.get(`${apiUrl}/starterpacks/${id}`, {
      timeout: 5000 // Timeout after 5 seconds
    });
    pack.value = response.data;
  } catch (err) {
    error.value = err.response?.data?.message || 'Failed to fetch pack data';
  } finally {
    loading.value = false;
  }
};


// Get the pack ID from the route parameters
const route = useRoute();
const packId = route.params.id;

// Fetch data on component mount
onMounted(() => {
  console.log('Fetching pack data...');
  fetchPackData(packId);
  console.log('Fetched pack data!');
});

// Computed property to calculate total upvotes
const totalUpvotes = computed(() => {
  return pack.value?.products.reduce((acc, product) => acc + product.upvotes, 0) || 0;
});

// Computed property to get the top 6 products sorted by upvotes
const topSixProducts = computed(() => {
  return [...(pack.value?.products || [])]
    .sort((a, b) => b.upvotes - a.upvotes)
});
</script>

<style scoped>
/* Add hover effect to the title */
.cursor-pointer {
  transition: color 0.3s ease-in-out;
}

.cursor-pointer:hover {
  color: #1D4ED8;
  /* Blue hover color */
}

/* Optional: Adjust hover effect for the card */
.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>