// src/router.js
import { createRouter, createWebHistory } from 'vue-router'

// Import your components that you want to route to
import HomeView from './views/HomeView.vue'
import PackView from './views/PackView.vue'

// Define your routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/starterpacks/:id',
    name: 'StarterPack',
    component: PackView,
  },
]

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

export default router
