<template>
    <div v-if="product && product.info" class="border rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow duration-300 ease-in-out flex flex-col w-full h-full">
        <!-- Image Preview -->


        <div class="relative overflow-hidden rounded-t-lg" style="padding-top: 50%;padding-bottom: 50%;">

            <ProductImage
  :image-url="product?.info?.image_url || ''"
  :product-url="product?.info?.product_url || '#'"
  :product-id="product?.id || 'default-id'"
  :pack-id="packIDRef || 'default-pack-id'"
  class="absolute inset-0 w-full h-full object-cover"
  :key="packIDRef"
/>

        </div>

        <!-- Product Details -->
        <div class="p-4 flex flex-col justify-between flex-grow">
            <!-- Product Name -->
            <a :href="product?.info?.product_url || '#'" target="_blank" class="text-lg font-semibold text-blue-600 hover:text-blue-700 hover:underline block truncate">
                {{ formattedProductName(product?.info?.short_name) || 'Unnamed Product' }}
            </a>

            <!-- Product Price -->
            <div v-if="product?.info?.price" class="text-emerald-500 text-xl font-semibold mt-2">
                ${{ product?.info?.price }}
            </div>

            <!-- Buy on Amazon Button -->
            <a :href="product?.info?.product_url || '#'" target="_blank"
                class="mt-4 px-4 py-2 bg-indigo-500 text-white font-bold rounded-lg hover:bg-indigo-600 transition-colors duration-300 block text-center">
                🛒 Buy on Amazon
            </a>

            <!-- Ratings and Upvotes Section -->
            <div class="flex justify-between items-center text-sm text-gray-500 mt-4">
                <!-- Star Rating -->
                <div v-if="product?.info?.customer_reviews" class="flex items-center space-x-1 text-yellow-500">
                    <span>⭐</span>
                    <span>{{ extractRating(product?.info?.customer_reviews) }} / 5</span>
                </div>

                <!-- Number of Ratings -->
                <div v-if="product?.info?.customer_reviews">
                    ({{ extractNumberOfRatings(product?.info?.customer_reviews) }} ratings)
                </div>

                <!-- Upvotes -->
                <div class="flex items-center">
                    <svg class="w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd"
                            d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm7-4a1 1 0 00-1 1v4a1 1 0 001 1 1 1 0 001-1V7a1 1 0 00-1-1zm-4 4a1 1 0 011-1h2a1 1 0 110 2H7a1 1 0 01-1-1z"
                            clip-rule="evenodd" />
                    </svg>
                    <span class="ml-1">🔥 {{ product.upvotes }} upvotes</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// Import the required components
import ProductImage from '../components/ProductImage.vue';
import { defineProps, ref, onMounted, watch } from 'vue';

// Props
const props = defineProps({
    product: {
        type: Object,
        required: true
    },
    packId: {
        type: [String, Number],
        required: true,
    }
});

// Reactive reference for pack ID
const packIDRef = ref("");

// Methods
const formattedProductName = (name) => {
    if (!name) return null;
    const cleanedName = name.replace(/^Amazon\.com:\s*/, '').split(' ');
    return cleanedName.slice(0, 10).join(' ');
};

const extractRating = (reviewText) => {
    const match = reviewText.match(/(\d\.\d) out of 5/);
    return match ? match[1] : 'N/A';
};

const extractNumberOfRatings = (reviewText) => {
    const match = reviewText.match(/(\d+)\s+ratings/);
    return match ? match[1] : 'N/A';
};

// Watch for changes to packId prop and update packIDRef
watch(() => props.packId, (newPackId) => {
    console.log('packIDRef updated:', newPackId);
    packIDRef.value = newPackId;
}, { immediate: true });

// Log product and packId for debugging
onMounted(() => {
    console.log('product.id:', props.product?.id);
    console.log('product.info.image_url:', props.product?.info?.image_url);
    console.log('packId:', props.packId);
    packIDRef.value = props.packId;
});
</script>
