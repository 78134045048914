<template>
    <div class="border rounded-lg shadow-md p-6 bg-white">
      <h2 class="text-xl font-semibold mb-4">Add a Product to Pack {{ packId }}</h2>
  
      <!-- Form for adding a new product -->
      <form @submit.prevent="submitProduct">
      
        <!-- Product URL -->
        <div class="mb-4">
          <label for="product-url" class="block text-sm font-medium text-gray-700">Product URL</label>
          <input
            v-model="productUrl"
            type="url"
            id="product-url"
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            required
          />
        </div>
  
        
  
        <!-- Submit Button -->
        <button
          type="submit"
          class="px-4 py-2 bg-indigo-500 text-white font-bold rounded-lg hover:bg-indigo-600 transition-colors duration-300"
        >
          Add Product
        </button>
      </form>
    </div>
  </template>
  
  <script setup>
  import axios from 'axios';
import { ref, defineProps, defineEmits, toRefs } from 'vue';
  
  // Destructure the packId prop and make it reactive
  const props = defineProps({
    packId: {
      type: [String, Number],
      required: true,
    },
  });
  
  const { packId } = toRefs(props); // Make packId reactive
  const isValidAmazonLink = (url) => {
    return /^(https?:\/\/)?(www\.)?(amazon\.[a-z]{2,})\/.*$/.test(url);
  };
  
  // Data for form inputs
  const productName = ref('');
  const productUrl = ref('');
  const imageUrl = ref('');
  const price = ref(null); // Initialize as null or 0 for a numeric value
  
  // Emit product data when the form is submitted
  const emit = defineEmits(['add-product']);
  
  const submitProduct = () => {

    if (!isValidAmazonLink(productUrl.value)) {
      alert('Please enter a valid Amazon product URL.');
      return;
    }
    // Create a product object
    const newProduct = {
        amazon_url: productUrl.value,
    };

    axios.post('https://api.starter-pack.online/starterpacks/'+packId.value+'/products/', newProduct)
  
    // Emit the new product to the parent
    emit('add-product', newProduct);
  
    // Clear form inputs after submission
    productName.value = '';
    productUrl.value = '';
    imageUrl.value = '';
    price.value = null;
  };
  </script>
  
  <style scoped>
  /* Custom styles for the form */
  </style>
  