<template>
    <div class="border rounded-lg shadow-md p-6 bg-white max-w-lg mx-auto">
      <!-- Section 1: Header -->
      <h2 class="text-2xl font-bold text-gray-800 text-center mb-6">Create a Starter Pack</h2>
  
      <!-- Section 2: Form -->
      <form @submit.prevent="submitPack">
        <!-- Title Input -->
        <div class="mb-4">
          <label class="block text-gray-700 font-semibold mb-2">Pack Title:</label>
          <input v-model="title" type="text" placeholder="Enter the title of the pack"
                 class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                 :class="{ 'border-red-500': titleError }" />
          <p v-if="titleError" class="text-red-500 text-sm mt-2">{{ titleError }}</p>
        </div>
  
        <!-- Product Links Input -->
        <div v-for="(link, index) in links" :key="index" class="mb-4">
          <label class="block text-gray-700 font-semibold mb-2">Product Link {{ index + 1 }}:</label>
          <input v-model="links[index]" type="url" placeholder="https://www.amazon.com/..."
                 class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                 :class="{ 'border-red-500': linkErrors[index] }" />
          <p v-if="linkErrors[index]" class="text-red-500 text-sm mt-2">{{ linkErrors[index] }}</p>
        </div>
  
        <!-- Add More Links Button -->
        <div class="mb-4">
          <button type="button" @click="addLink"
                  class="px-4 py-2 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600 transition-colors duration-300">
            Add Another Link
          </button>
        </div>
  
        <!-- Validation Error Messages -->
        <div v-if="generalError" class="text-red-500 text-center mb-4">
          {{ generalError }}
        </div>
  
        <!-- Submit Button -->
        <div class="text-center">
          <button type="submit" 
                  class="px-6 py-2 bg-indigo-500 text-white font-bold rounded-lg hover:bg-indigo-600 transition-colors duration-300">
            Create Pack
          </button>
        </div>
      </form>
    </div>
  </template>
  
  <script setup>
  import axios from 'axios';
import { ref } from 'vue';
  
  const title = ref('');
  const links = ref(['', '', '', '', '', '']); // Start with 6 blank inputs
  const linkErrors = ref(new Array(6).fill(null)); // Track errors for each link
  const titleError = ref(null);
  const generalError = ref(null);
  
  // Function to validate if a URL is an Amazon link
  const isValidAmazonLink = (url) => {
    return /^(https?:\/\/)?(www\.)?(amazon\.[a-z]{2,})\/.*$/.test(url);
  };
  
  // Function to validate the form inputs
  const validateForm = () => {
    titleError.value = null;
    generalError.value = null;
    linkErrors.value = new Array(links.value.length).fill(null);
  
    let valid = true;
  
    // Validate title
    if (!title.value.trim()) {
      titleError.value = "Title is required.";
      valid = false;
    }
  
    // Validate links
    const filledLinks = links.value.filter(link => link.trim() !== '');
    if (filledLinks.length < 6) {
      generalError.value = "You must add at least 6 product links.";
      valid = false;
    }
  
    filledLinks.forEach((link, index) => {
      if (!isValidAmazonLink(link)) {
        linkErrors.value[index] = "Link must be a valid Amazon URL.";
        valid = false;
      }
    });
  
    return valid;
  };
  
  // Function to handle the form submission
  const submitPack = () => {
    if (validateForm()) {
        // Create a new pack object
        const newPack = {
            name: title.value,
            products: links.value.filter(link => link.trim() !== '')
            };

    // Send a POST request to the API
    axios.post('https://api.starter-pack.online/starterpacks/', newPack)
      title.value = '';
      links.value = ['','','','','',''];
      linkErrors.value = new Array(6).fill(null);
      generalError.value = null;
    }
  };
  
  // Add more link input fields
  const addLink = () => {
    links.value.push('');
    linkErrors.value.push(null);
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  